import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_GHTF_LIST,
  REQUEST_DETAIL_GHTF_LIST_SUCCESS,
  REQUEST_DETAIL_GHTF_LIST_ERROR,
  REQUEST_DETAIL_GHTF_PAGE_SET,
  REQUEST_DETAIL_GHTF_SEARCH_SET,
  REQUEST_DETAIL_GHTF_SEARCH_CHANGE,
  REQUEST_DETAIL_GHTF_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_GHTF_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_DETAIL_GHTF_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_DETAIL_GHTF_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_GHTF_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  REQUEST_DETAIL_GHTF_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  REQUEST_DETAIL_GHTF_ADD_SET,
  REQUEST_DETAIL_GHTF_ADD_MODAL_SET,
  REQUEST_DETAIL_GHTF_ADD,
  REQUEST_DETAIL_GHTF_ADD_SUCCESS,
  REQUEST_DETAIL_GHTF_ADD_ERROR,
  REQUEST_DETAIL_GHTF_ADD_CHANGE,
  REQUEST_DETAIL_GHTF_DETAIL,
  REQUEST_DETAIL_GHTF_DETAIL_SUCCESS,
  REQUEST_DETAIL_GHTF_DETAIL_ERROR,
  REQUEST_DETAIL_GHTF_VIEW_MODAL_SET,
  REQUEST_DETAIL_GHTF_EDIT_CHANGE,
  REQUEST_DETAIL_GHTF_EDIT_MODAL_SET,
  REQUEST_DETAIL_GHTF_UPDATE,
  REQUEST_DETAIL_GHTF_UPDATE_SUCCESS,
  REQUEST_DETAIL_GHTF_UPDATE_ERROR,
  REQUEST_DETAIL_GHTF_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_detail_ghtf';

export const RequestDetailGhtfList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_DETAIL_GHTF_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestDetailGhtfListSuccess = (data) => ({
  type: REQUEST_DETAIL_GHTF_LIST_SUCCESS,
  payload: data
});

export const RequestDetailGhtfListError = (error) => ({
  type: REQUEST_DETAIL_GHTF_LIST_ERROR,
  payload: error
});

export const RequestDetailGhtfPageSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_PAGE_SET,
  payload: data
});

export const RequestDetailGhtfSearchSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_SEARCH_SET,
  payload: data
});

export const RequestDetailGhtfSearchChangeSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_SEARCH_CHANGE,
  payload: data
});

export const RequestDetailGhtfSearchGeoCountryList = (data) => ({
  type: REQUEST_DETAIL_GHTF_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestDetailGhtfSearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_DETAIL_GHTF_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailGhtfSearchGeoCountryListError = (error) => ({
  type: REQUEST_DETAIL_GHTF_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
});

export const RequestDetailGhtfSearchHealthAgencyList = (data) => ({
  type: REQUEST_DETAIL_GHTF_SEARCH_HEALTH_AGENCY_LIST,
  payload: data
});

export const RequestDetailGhtfSearchHealthAgencyListSuccess = (message) => ({
  type: REQUEST_DETAIL_GHTF_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailGhtfSearchHealthAgencyListError = (error) => ({
  type: REQUEST_DETAIL_GHTF_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  payload: error
});

export const RequestDetailGhtfAddChangeSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_ADD_CHANGE,
  payload: data
});

export const RequestDetailGhtfAddSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_ADD_SET,
  payload: data
});

export const RequestDetailGhtfAddModalSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_ADD_MODAL_SET,
  payload: data
});

export const RequestDetailGhtfAdd = (data) => ({
  type: REQUEST_DETAIL_GHTF_ADD,
  payload: data
});

export const RequestDetailGhtfAddSuccess = (message) => ({
  type: REQUEST_DETAIL_GHTF_ADD_SUCCESS,
  payload: message
});

export const RequestDetailGhtfAddError = (error) => ({
  type: REQUEST_DETAIL_GHTF_ADD_ERROR,
  payload: error
});

export const RequestDetailGhtfDetail = (data) => ({
  type: REQUEST_DETAIL_GHTF_DETAIL,
  payload: data
});

export const RequestDetailGhtfDetailSuccess = (data) => ({
  type: REQUEST_DETAIL_GHTF_DETAIL_SUCCESS,
  payload: data
});

export const RequestDetailGhtfDetailError = (error) => ({
  type: REQUEST_DETAIL_GHTF_DETAIL_ERROR,
  payload: error
});

export const RequestDetailGhtfViewModalSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_VIEW_MODAL_SET,
  payload: data
});

export const RequestDetailGhtfEditModalSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailGhtfEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_EDIT_CHANGE,
  payload: data
});

export const RequestDetailGhtfUpdate = (data) => ({
  type: REQUEST_DETAIL_GHTF_UPDATE,
  payload: data
});

export const RequestDetailGhtfUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_GHTF_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailGhtfUpdateError = (error) => ({
  type: REQUEST_DETAIL_GHTF_UPDATE_ERROR,
  payload: error
});

export const RequestDetailGhtfViewTabSet = (data) => ({
  type: REQUEST_DETAIL_GHTF_VIEW_TAB_SET,
  payload: data
});

const request_detail_ghtf = (state) => state[NAME];

export const RequestDetailGhtfSelector = createStructuredSelector({
  request_detail_ghtf
})
