import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import process from './process/reducer';
import request from './request/reducer';
import request_detail from './request_detail/reducer';
import search from './search/reducer';
import request_answer from './request_answer/reducer';
import upload from './upload/reducer';
import request_detail_file from './request_detail_file/reducer';
import request_geo_country from './request_geo_country/reducer';
import request_experience from './request_experience/reducer';
import request_user_chat from './request_user_chat/reducer';
import request_message from './request_message/reducer';
import request_detail_health_registry from './request_detail_health_registry/reducer';
import request_detail_delivery_term from './request_detail_delivery_term/reducer';
import supplier_email from './supplier_email/reducer';
import request_detail_bmp_agency_maker from './request_detail_bmp_agency_maker/reducer';
import request_detail_bmp_agency_packager from './request_detail_bmp_agency_packager/reducer';
import request_detail_iso from './request_detail_iso/reducer';
import request_detail_ghtf from './request_detail_ghtf/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  process,
  request,
  request_detail,
  search,
  request_answer,
  upload,
  request_detail_file,
  request_geo_country,
  request_experience,
  request_user_chat,
  request_message,
  request_detail_health_registry,
  request_detail_delivery_term,
  supplier_email,
  request_detail_bmp_agency_maker,
  request_detail_bmp_agency_packager,
  request_detail_iso,
  request_detail_ghtf
});

export default reducers;
