import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_DETAIL_GHTF_LIST,
  REQUEST_DETAIL_GHTF_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_GHTF_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_GHTF_ADD,
  REQUEST_DETAIL_GHTF_DETAIL,
  REQUEST_DETAIL_GHTF_UPDATE,
} from '../actions';
import {
  RequestDetailGhtfListSuccess,
  RequestDetailGhtfListError,
  RequestDetailGhtfSearchGeoCountryListSuccess,
  RequestDetailGhtfSearchGeoCountryListError,
  RequestDetailGhtfSearchHealthAgencyListSuccess,
  RequestDetailGhtfSearchHealthAgencyListError,
  RequestDetailGhtfAddSuccess,
  RequestDetailGhtfAddError,
  RequestDetailGhtfDetailSuccess,
  RequestDetailGhtfDetailError,
  RequestDetailGhtfUpdateSuccess,
  RequestDetailGhtfUpdateError
} from '../actions';
 
export function* watchRequestDetailGhtfList() {
  yield takeEvery(REQUEST_DETAIL_GHTF_LIST, onRequestDetailGhtfList);
}

export function* watchRequestDetailGhtfSearchHealthAgencyList() {
  yield takeEvery(REQUEST_DETAIL_GHTF_SEARCH_HEALTH_AGENCY_LIST, onRequestDetailGhtfSearchHealthAgencyList);
}

export function* watchRequestDetailGhtfSearchGeoCountryList() {
  yield takeEvery(REQUEST_DETAIL_GHTF_SEARCH_GEO_COUNTRY_LIST, onRequestDetailGhtfSearchGeoCountryList);
}

export function* watchRequestDetailGhtfAdd() {
  yield takeEvery(REQUEST_DETAIL_GHTF_ADD, onRequestDetailGhtfAdd);
}

export function* watchRequestDetailGhtfDetail() {
  yield takeEvery(REQUEST_DETAIL_GHTF_DETAIL, onRequestDetailGhtfDetail);
}

export function* watchRequestDetailGhtfUpdate() {
  yield takeEvery(REQUEST_DETAIL_GHTF_UPDATE, onRequestDetailGhtfUpdate);
}

const onRequestDetailGhtfListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_ghtf/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailGhtfUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_ghtf/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}


const onRequestDetailGhtfAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_ghtf/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestDetailGhtfSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailGhtfSearchHealthAgencyListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/health_agency', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailGhtfDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_ghtf/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestDetailGhtfList(data) {
  try {
    const response = yield call(onRequestDetailGhtfListAsync, data.payload);
    yield put(RequestDetailGhtfListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailGhtfListError(error));
  }
}

function* onRequestDetailGhtfSearchHealthAgencyList(data) {
  try {
    const response = yield call(onRequestDetailGhtfSearchHealthAgencyListAsync, data.payload);
    yield put(RequestDetailGhtfSearchHealthAgencyListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailGhtfSearchHealthAgencyListError(error));
  }
}

function* onRequestDetailGhtfSearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestDetailGhtfSearchGeoCountryListAsync, data.payload);
    yield put(RequestDetailGhtfSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailGhtfSearchGeoCountryListError(error));
  }
}

function* onRequestDetailGhtfAdd(data) {
  try {
      const response = yield call(onRequestDetailGhtfAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailGhtfAddSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailGhtfAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestDetailGhtfDetail(data) {
  try {
    const response = yield call(onRequestDetailGhtfDetailAsync, data.payload);
    yield put(RequestDetailGhtfDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailGhtfDetailError(error));
  }
}

function* onRequestDetailGhtfUpdate(data) {
  try {
      const response = yield call(onRequestDetailGhtfUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailGhtfUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailGhtfUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailGhtfList),
    fork(watchRequestDetailGhtfSearchGeoCountryList),
    fork(watchRequestDetailGhtfSearchHealthAgencyList),
    fork(watchRequestDetailGhtfAdd),
    fork(watchRequestDetailGhtfDetail),
    fork(watchRequestDetailGhtfUpdate),
  ]);
} 