import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_ISO_LIST,
  REQUEST_DETAIL_ISO_LIST_SUCCESS,
  REQUEST_DETAIL_ISO_LIST_ERROR,
  REQUEST_DETAIL_ISO_PAGE_SET,
  REQUEST_DETAIL_ISO_SEARCH_SET,
  REQUEST_DETAIL_ISO_SEARCH_CHANGE,
  REQUEST_DETAIL_ISO_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_ISO_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_DETAIL_ISO_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_DETAIL_ISO_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_ISO_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  REQUEST_DETAIL_ISO_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  REQUEST_DETAIL_ISO_ADD_SET,
  REQUEST_DETAIL_ISO_ADD_MODAL_SET,
  REQUEST_DETAIL_ISO_ADD,
  REQUEST_DETAIL_ISO_ADD_SUCCESS,
  REQUEST_DETAIL_ISO_ADD_ERROR,
  REQUEST_DETAIL_ISO_ADD_CHANGE,
  REQUEST_DETAIL_ISO_DETAIL,
  REQUEST_DETAIL_ISO_DETAIL_SUCCESS,
  REQUEST_DETAIL_ISO_DETAIL_ERROR,
  REQUEST_DETAIL_ISO_VIEW_MODAL_SET,
  REQUEST_DETAIL_ISO_EDIT_CHANGE,
  REQUEST_DETAIL_ISO_EDIT_MODAL_SET,
  REQUEST_DETAIL_ISO_UPDATE,
  REQUEST_DETAIL_ISO_UPDATE_SUCCESS,
  REQUEST_DETAIL_ISO_UPDATE_ERROR,
  REQUEST_DETAIL_ISO_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_detail_iso';

export const RequestDetailIsoList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_DETAIL_ISO_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestDetailIsoListSuccess = (data) => ({
  type: REQUEST_DETAIL_ISO_LIST_SUCCESS,
  payload: data
});

export const RequestDetailIsoListError = (error) => ({
  type: REQUEST_DETAIL_ISO_LIST_ERROR,
  payload: error
});

export const RequestDetailIsoPageSet = (data) => ({
  type: REQUEST_DETAIL_ISO_PAGE_SET,
  payload: data
});

export const RequestDetailIsoSearchSet = (data) => ({
  type: REQUEST_DETAIL_ISO_SEARCH_SET,
  payload: data
});

export const RequestDetailIsoSearchChangeSet = (data) => ({
  type: REQUEST_DETAIL_ISO_SEARCH_CHANGE,
  payload: data
});

export const RequestDetailIsoSearchGeoCountryList = (data) => ({
  type: REQUEST_DETAIL_ISO_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestDetailIsoSearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_DETAIL_ISO_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailIsoSearchGeoCountryListError = (error) => ({
  type: REQUEST_DETAIL_ISO_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
});

export const RequestDetailIsoSearchHealthAgencyList = (data) => ({
  type: REQUEST_DETAIL_ISO_SEARCH_HEALTH_AGENCY_LIST,
  payload: data
});

export const RequestDetailIsoSearchHealthAgencyListSuccess = (message) => ({
  type: REQUEST_DETAIL_ISO_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailIsoSearchHealthAgencyListError = (error) => ({
  type: REQUEST_DETAIL_ISO_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  payload: error
});

export const RequestDetailIsoAddChangeSet = (data) => ({
  type: REQUEST_DETAIL_ISO_ADD_CHANGE,
  payload: data
});

export const RequestDetailIsoAddSet = (data) => ({
  type: REQUEST_DETAIL_ISO_ADD_SET,
  payload: data
});

export const RequestDetailIsoAddModalSet = (data) => ({
  type: REQUEST_DETAIL_ISO_ADD_MODAL_SET,
  payload: data
});

export const RequestDetailIsoAdd = (data) => ({
  type: REQUEST_DETAIL_ISO_ADD,
  payload: data
});

export const RequestDetailIsoAddSuccess = (message) => ({
  type: REQUEST_DETAIL_ISO_ADD_SUCCESS,
  payload: message
});

export const RequestDetailIsoAddError = (error) => ({
  type: REQUEST_DETAIL_ISO_ADD_ERROR,
  payload: error
});

export const RequestDetailIsoDetail = (data) => ({
  type: REQUEST_DETAIL_ISO_DETAIL,
  payload: data
});

export const RequestDetailIsoDetailSuccess = (data) => ({
  type: REQUEST_DETAIL_ISO_DETAIL_SUCCESS,
  payload: data
});

export const RequestDetailIsoDetailError = (error) => ({
  type: REQUEST_DETAIL_ISO_DETAIL_ERROR,
  payload: error
});

export const RequestDetailIsoViewModalSet = (data) => ({
  type: REQUEST_DETAIL_ISO_VIEW_MODAL_SET,
  payload: data
});

export const RequestDetailIsoEditModalSet = (data) => ({
  type: REQUEST_DETAIL_ISO_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailIsoEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_ISO_EDIT_CHANGE,
  payload: data
});

export const RequestDetailIsoUpdate = (data) => ({
  type: REQUEST_DETAIL_ISO_UPDATE,
  payload: data
});

export const RequestDetailIsoUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_ISO_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailIsoUpdateError = (error) => ({
  type: REQUEST_DETAIL_ISO_UPDATE_ERROR,
  payload: error
});

export const RequestDetailIsoViewTabSet = (data) => ({
  type: REQUEST_DETAIL_ISO_VIEW_TAB_SET,
  payload: data
});

const request_detail_iso = (state) => state[NAME];

export const RequestDetailIsoSelector = createStructuredSelector({
  request_detail_iso
})
