import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_DETAIL_ISO_LIST,
  REQUEST_DETAIL_ISO_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_ISO_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_ISO_ADD,
  REQUEST_DETAIL_ISO_DETAIL,
  REQUEST_DETAIL_ISO_UPDATE,
} from '../actions';
import {
  RequestDetailIsoListSuccess,
  RequestDetailIsoListError,
  RequestDetailIsoSearchGeoCountryListSuccess,
  RequestDetailIsoSearchGeoCountryListError,
  RequestDetailIsoSearchHealthAgencyListSuccess,
  RequestDetailIsoSearchHealthAgencyListError,
  RequestDetailIsoAddSuccess,
  RequestDetailIsoAddError,
  RequestDetailIsoDetailSuccess,
  RequestDetailIsoDetailError,
  RequestDetailIsoUpdateSuccess,
  RequestDetailIsoUpdateError
} from '../actions';
 
export function* watchRequestDetailIsoList() {
  yield takeEvery(REQUEST_DETAIL_ISO_LIST, onRequestDetailIsoList);
}

export function* watchRequestDetailIsoSearchHealthAgencyList() {
  yield takeEvery(REQUEST_DETAIL_ISO_SEARCH_HEALTH_AGENCY_LIST, onRequestDetailIsoSearchHealthAgencyList);
}

export function* watchRequestDetailIsoSearchGeoCountryList() {
  yield takeEvery(REQUEST_DETAIL_ISO_SEARCH_GEO_COUNTRY_LIST, onRequestDetailIsoSearchGeoCountryList);
}

export function* watchRequestDetailIsoAdd() {
  yield takeEvery(REQUEST_DETAIL_ISO_ADD, onRequestDetailIsoAdd);
}

export function* watchRequestDetailIsoDetail() {
  yield takeEvery(REQUEST_DETAIL_ISO_DETAIL, onRequestDetailIsoDetail);
}

export function* watchRequestDetailIsoUpdate() {
  yield takeEvery(REQUEST_DETAIL_ISO_UPDATE, onRequestDetailIsoUpdate);
}

const onRequestDetailIsoListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_iso/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailIsoUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_iso/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}


const onRequestDetailIsoAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_iso/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestDetailIsoSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailIsoSearchHealthAgencyListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/health_agency', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailIsoDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_iso/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestDetailIsoList(data) {
  try {
    const response = yield call(onRequestDetailIsoListAsync, data.payload);
    yield put(RequestDetailIsoListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailIsoListError(error));
  }
}

function* onRequestDetailIsoSearchHealthAgencyList(data) {
  try {
    const response = yield call(onRequestDetailIsoSearchHealthAgencyListAsync, data.payload);
    yield put(RequestDetailIsoSearchHealthAgencyListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailIsoSearchHealthAgencyListError(error));
  }
}

function* onRequestDetailIsoSearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestDetailIsoSearchGeoCountryListAsync, data.payload);
    yield put(RequestDetailIsoSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailIsoSearchGeoCountryListError(error));
  }
}

function* onRequestDetailIsoAdd(data) {
  try {
      const response = yield call(onRequestDetailIsoAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailIsoAddSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailIsoAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestDetailIsoDetail(data) {
  try {
    const response = yield call(onRequestDetailIsoDetailAsync, data.payload);
    yield put(RequestDetailIsoDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailIsoDetailError(error));
  }
}

function* onRequestDetailIsoUpdate(data) {
  try {
      const response = yield call(onRequestDetailIsoUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailIsoUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailIsoUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailIsoList),
    fork(watchRequestDetailIsoSearchGeoCountryList),
    fork(watchRequestDetailIsoSearchHealthAgencyList),
    fork(watchRequestDetailIsoAdd),
    fork(watchRequestDetailIsoDetail),
    fork(watchRequestDetailIsoUpdate),
  ]);
} 